.loader{
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    i{
        animation: spin 2s linear infinite;
    }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }