$black:#000;
$spans:#800020;
$bg:darken($spans,15);
$white:#fff;
$title:darken($spans,20);
$text_dest:darken($spans,10);
$color_gradiant:darken($spans,0);
$cancelled:rgb(250, 61, 61);
$in_progress:rgb(22, 221, 22);
$pending:#2bcbff;
//gradientes
$btn:linear-gradient(to bottom, $color_gradiant 0%,$title 100%);
$btn_hover:linear-gradient(to bottom,$title  0%, $color_gradiant 100%);