.btn_login {
    background: $btn;
    color: $white;
    font-weight: bold;

    &:hover {
        background: $btn_hover;
    }
}
button {
    color: $white !important;
    ;
    &:hover {
        color: $white !important;
    }
}
