body {
    margin: 0;
    padding: 0;
    background: $bg;
}

.mh85 {
    min-height: 85vh;
}

.bg_black {
    background-color: darken($spans,70);
}

.bg_light {
    background: $bg;
}

.bg_spans {
    background-color: $spans;
}

.bg_title {
    background: $title;
}

.shadow_title {
    text-shadow: 1px 1px 3px $black, -1px -1px 3px $black;
}

.text_dest {
    color: lighten($text_dest, 50) ;
}
