@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

.servicios_box {
    width: 12.5%;
    padding: 1%;
    height: 200px;
    float: left;
    background: $spans;
    color: $white;
}

.servicio_sp,
.live_casino,
.online_casino,
.live_betting,
.races,
.poker,
.prop_builder,
.call_center {
    background-image: url('../../img/services/sp.webp');
    background-size: cover;
    text-shadow: 0 0 5px $black;
    font-weight: bold;
    overflow: hidden;
    box-shadow: 0 0 5px $black;
    transition: all .1s linear;
    box-shadow: 0 0 5px inset darken($spans,85) ;
    &:hover {
        box-shadow: 0 0 18px darken($spans,45) ;
        border: 1px outset $spans;
    }
}

.live_casino {
    background-image: url('../../img/services/live_casino.webp');
}

.online_casino {
    background-image: url('../../img/services/online_casino.webp');
}

.live_betting {
    background-image: url('../../img/services/live_betting.webp');
}

.races {
    background-image: url('../../img/services/races.webp');
}

.poker {
    background-image: url('../../img/services/poker.webp');
}

.prop_builder {
    background-image: url('../../img/services/prop_builder.webp');
}

.call_center {
    background-image: url('../../img/services/call_center.webp');
}

.span_title_services {
    font-family: 'Audiowide', cursive;
    bottom: -70%;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 2% 0;
    float: left;
    background: rgba($title, .5);
    font-size: 1.3rem;
}

@media only screen and (max-width: 912px) {
    .servicios_box {
        width: 50%;
        padding: 1%;
        height: 200px;
        float: left;
        color: $white;
    }
}